<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.blacklist')"
        :showButton="false"
        :buttonTitle="$t('xpbx.button.create_user')"
        @buttonClick="createRecord"
      />
    </section>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";

export default {
  name: "Blacklist",

  components: {
    PageHeader,
  },

  setup() {
    const router = useRouter();

    const createRecord = () => {
      console.log("Create Record");
      //   router.push({ name: "CreateUser" });
    };

    return {
      createRecord,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>